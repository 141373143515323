<script setup>
const emit = defineEmits(['onClick'])
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  active: {
    type: Boolean,
    default: false
  },
  style: {
    type: Object
  },
})
const onClick = () => {
  emit('onClick')
}
</script>

<template>
  <button
    class="tab"
    :class="[ {'active':active} ]"
    :disabled="disabled"
    @click="onClick"
    :style="style"
  >
    <slot />
  </button>
</template>

<style scoped lang="scss">
.tab {
  box-sizing: border-box;
  color: $gray5;
  @include set-text;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 54px;
  background: $white;
  // border-bottom: 2px solid $gray2;
  &:disabled {
    color: $gray3;
    border-color: $t2;
    background-color: $gray2;
  }
  &.active {
    color: $primary5;
    font-weight: 500;
    border-bottom: 4px solid $primary5;
    // border-width: 4px;
    // border-color: $primary5;
    z-index: 1;
    text-shadow: 0px 2px 5px rgba(153, 175, 255, 0.50);
  }
}
</style>
